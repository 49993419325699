.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.guess {
  height: min(10vw, 3.2rem);
  margin-left: .125rem;
  margin-right: .125rem;
  font-size: min(3.125rem, 8vw);
  line-height: 3.75rem;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-bottom: min(1vw, .125rem);
}

.guessbox {
  width: min(5rem, 10vw);
  border: 2px solid #000;
}

.paren {
  width: min(2rem, 3vw);
  margin-left: 0.125rem;
  margin-right: 0;
}

.right {
  background-color: #80EF80;
}

.wrong {
  background-color: #A0A0A0;
}

.place {
  background-color: #FFFF00;
}

.guessrow {
  justify-content: center;
  display: flex;
  background-color:white;
}

button {
  margin-left: .2vw;
  margin-right: .2vw;
  font-size: min(5vw, 2rem);
}

.buttonrow {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

h1 {
  background-color: white;
}

.victory {
  background-color: #80EF80;
}

.defeat {
  background-color: #FF0000;
}

.sidemenu {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 25;
}

.sidemenu_content {
  box-sizing: border-box;
  position: relative;
  top: 0;
  left: 0;
  z-index: 10;
  height: 100%;
  width: 70%;
  max-width: 26rem;
  background-color: white;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
  transform: translateX(-103%);
  transition: transform 300ms linear;
}

.sidemenu_content-active {
  transform: none;
}

.sidemenu_overlay {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #222222;
  opacity: 0;
}

.sidemenu-active {
  pointer-events: auto;
}

.sidemenu-active .sidemenu_overlay {
  opacity: 0.3;
}

.options {
  text-align: left;
}